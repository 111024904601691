<template>
    <div :id="config.affichage_page_accueil ? 'home-blank-bg' : 'home'">
        <div v-if="config.affichage_page_accueil">
            <div class="main-title mb-0">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h3>
                                {{$t('accueil.bienvenue-sur-la-plateforme')}} {{config.etablissement.display_name}}</h3>
                            <div class="underline"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 mt-5">
                            <div class="card bg-light">
                                <div class="card-body">
                                    <div v-html="$t('accueil.presentation-e-rdv')">

                                    </div>
                                    <div class="mt-3">
                                         {{$t('accueil.vous-etes-actuellement')}} {{config.etablissement.display_name}}
                                        <br>
                                        <div v-if="getDoctors && getDoctors.length>1">
                                            {{$t('accueil.voici-les-professionels')}}
                                            <ul>
                                                <li v-for="doctor in getDoctors.filter(d=> d.id !== 0)">
                                                    <router-link  :to="'/prise-de-rendez-vous/'+doctor.id" >{{doctor.name + ' ' + doctor.firstname}}</router-link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div v-if="getDoctors && getDoctors.length===1">
                                            Prenez rendez-vous avec <router-link :to="'/prise-de-rendez-vous/'+getDoctors[0].id" >{{getDoctors[0].name +' ' + getDoctors[0].firstname}}</router-link> en quelques clics!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mt-3 mb-3">
                            <div v-if="!getUser" class="card border border-primary pt-5 pb-5 pl-3 pr-3 bg-light h-100">
                                <div class="card-body h-100 d-flex flex-column justify-content-between">
                                    <div v-html="$t('accueil.pas-encore-inscrit')">
                                    </div>
                                    <div>
                                        <router-link to="/inscription" class="btn btn-block btn-primary text-wite">
                                            {{ $t('accueil.inscription') }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="card border border-primary pt-5 pb-5 pl-3 pr-3 bg-light h-100">
                                <div class="card-body h-100 d-flex flex-column justify-content-between">
                                    <div>
                                        {{ $t('accueil.bienvenue') }} {{getUser.first_name +' ' + getUser.last_name}} ! <br>
                                        {{ $t('accueil.voir-rdv') }}
                                    </div>
                                    <div>
                                        <router-link to="/mes-rendez-vous" class="btn btn-block btn-primary text-wite">
                                            {{ $t('accueil.mes-rdv') }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-3 mb-3">
                            <div v-if="!getUser" class="card border border-primary pt-5 pb-5 pl-3 pr-3 bg-light h-100">
                                <div class="card-body h-100 d-flex flex-column justify-content-between">
                                    <div v-html="$t('accueil.deja-inscrit')">
                                    </div>
                                    <div>
                                        <button data-toggle="modal" data-target="#connexionModal" class="btn btn-block btn-primary text-wite">
                                            {{ $t('global.connexion') }}
                                        </button>
                                    </div>

                                </div>
                            </div>
                            <div v-else class="card border border-primary pt-5 pb-5 pl-3 pr-3 bg-light h-100">
                                <div class="card-body h-100 d-flex flex-column justify-content-between">
                                    <div v-html="$t('accueil.voir-vos-informations')">

                                    </div>
                                    <div>
                                        <router-link to="/mon-compte" class="btn btn-block btn-primary text-wite">
                                            {{$t('accueil.mon-compte')}}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-3 mb-3">
                            <div class="card border border-primary pt-5 pb-5 pl-3 pr-3 bg-light h-100">
                                <div class="card-body h-100 d-flex flex-column justify-content-between">
                                    <div v-html="$t('accueil.disponibilites')">

                                    </div>
                                    <div>
                                        <router-link to="/prise-de-rendez-vous" class="btn btn-block btn-primary text-wite">
                                            {{$t('accueil.voir-disponibilites')}}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal-password-reset :token="token"></modal-password-reset>
    </div>
</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'

    import {mapActions, mapGetters} from "vuex";
    import {namespaces} from "@/store/namespaces";
    import ModalPasswordReset from "@/components/front/ModalPasswordReset.vue";
    import authApi from "@/api/auth";

    export default {
        name: 'Home',
        metaInfo() {
            // if no subcomponents specify a metaInfo.title, this title will be used
            let self = this
            return {
                title: 'Accueil',
                meta: [{
                    name: 'description',
                    content: self.metaDescription
                }]
            }
        },
        components: {
            ModalPasswordReset,
        },
        props: {
            passwordReset: {
                required: false,
            },
            token: {
                required: false,
                default() {
                    return null
                }
            }
        },
        data() {
            return {
                config: Vue.$config,
                doctors: null
            }
        },
        computed: {
            ...mapGetters({
                getOffices: namespaces.calendar + 'getOffices',
                getDoctors: namespaces.calendar + 'getDoctors',
                getReasons: namespaces.calendar + 'getReasons',
                getUser: namespaces.auth + 'getUser',
            }),
            metaDescription() {
                let doctorString = ''
                if(this.getDoctors) {
                    this.getDoctors.forEach((d, i)=> {
                        if(d.id!==0) {
                            doctorString+= d.firstname + ' ' + d.name + (i +1 !== this.getDoctors.length ? ', ' : '')
                        }
                    })
                    return 'Page d\'accueil de la prise de rendez vous en ligne du '+Vue.$config.etablissement.display_name+' (prise de rendez-vous possible avec ' + doctorString + ')'
                }
                return 'Prise de rendez vous en ligne avec'
            }
        },
        methods: {
            ...mapActions({
                setOffices: namespaces.calendar + 'setOffices',
                setDoctors: namespaces.calendar + 'setDoctors',
                setReasons: namespaces.calendar + 'setReasons',
                setUser: namespaces.auth + 'setUser',
                setApiToken: namespaces.auth + 'setApiToken',
                loginAs: namespaces.auth + 'loginAs',
            }),
        },
        async mounted() {
            if(!this.getOffices) await this.setOffices()
            if(!this.getDoctors)await this.setDoctors()
            if(!this.getReasons)await this.setReasons()
            if(this.passwordReset && this.token) {
                $('#password-reset-modal').modal('show')
            }
            if(this.$route.params.userId && this.$route.params.apiToken) {
                await this.loginAs({userId: this.$route.params.userId, apiToken: this.$route.params.apiToken})
            }
        }
    }
</script>
<style lang="scss">
    #app {
        display: flex;
        flex-direction: column;
    }

    body {
        min-height: 100vh !important;
    }

    #home {
        flex-grow: 1;
        min-height: 100vh;
        background-image: url("~@/assets/medical.jpg");
        background-size: cover;
        background-position: center center;
    }
    #home-blank-bg {
        flex-grow: 1;
        min-height: 100vh;
        background-color: white;
        background-size: cover;
        background-position: center center;
    }

</style>
